import Icon from "@ant-design/icons";
import type { GetProps } from "antd";

type CustomIconComponentProps = GetProps<typeof Icon>;

const GeoPersonaSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 52 52"
      fill="none"
    >
      <circle cx="27" cy="23" r="7" stroke="currentColor" strokeWidth="3" />
      <path
        d="M27 34C21.09 34 15.7787 37.1084 12.1174 40.6333C8.13871 44.4638 11.4772 49 17 49H37C42.5228 49 45.8613 44.4638 41.8826 40.6333C38.2213 37.1084 32.91 34 27 34Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M12 23C12 20.2386 9.76142 18 7 18C9.76142 18 12 15.7614 12 13C12 15.7614 14.2386 18 17 18C14.2386 18 12 20.2386 12 23Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M37 14C37 10.6863 34.3137 8 31 8C34.3137 8 37 5.31371 37 2C37 5.31371 39.6863 8 43 8C39.6863 8 37 10.6863 37 14Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlacesSvg = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0003 23.1426C18.1543 23.1426 23.1431 18.1538 23.1431 11.9998C23.1431 5.84576 18.1543 0.856934 12.0003 0.856934C5.84625 0.856934 0.857422 5.84576 0.857422 11.9998C0.857422 18.1538 5.84625 23.1426 12.0003 23.1426Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.71484 16.2856H4.71484C5.51049 16.2856 6.27355 15.9696 6.83616 15.4069C7.39877 14.8443 7.71484 14.0813 7.71484 13.2856V10.7142C7.71484 9.91854 8.03091 9.15548 8.59352 8.59287C9.15613 8.03026 9.91919 7.71419 10.7148 7.71419C11.5105 7.71419 12.2736 7.39812 12.8362 6.83551C13.3988 6.2729 13.7148 5.50984 13.7148 4.71419V0.977051"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.1434 11.8283C22.2851 11.3829 21.3332 11.1479 20.3663 11.1426H16.7148C15.9192 11.1426 15.1561 11.4586 14.5935 12.0213C14.0309 12.5839 13.7148 13.3469 13.7148 14.1426C13.7148 14.9382 14.0309 15.7013 14.5935 16.2639C15.1561 16.8265 15.9192 17.1426 16.7148 17.1426C17.2832 17.1426 17.8282 17.3683 18.2301 17.7702C18.6319 18.1721 18.8577 18.7171 18.8577 19.2854V20.7769"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GeoPersonaIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={GeoPersonaSvg} {...props} />
);

export const PlacesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PlacesSvg} {...props} />
);
