import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen.ts";
import { ConfigProvider, App as AntdApp } from "antd";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import utc from "dayjs/plugin/utc";
import { extendedToken } from "src/constants/theme.ts";
import { ErrorComponent } from "src/components/ErrorComponent.tsx";
import "./datadog.ts";
import "./globalAxiosConfig.ts";
import "antd/dist/reset.css";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);

const globalTheme = {
  token: {
    fontFamily: '"Nunito", sans-serif',
    ...extendedToken,
  },
  components: {
    Menu: {
      darkSubMenuItemBg: "#0A3259",
    },
    Layout: {
      bodyBg: "#fff",
    },
    Tabs: {
      horizontalMargin: "0",
    },
  },
};

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function InnerApp() {
  return (
    <AntdApp>
      <RouterProvider router={router} defaultErrorComponent={ErrorComponent} />
    </AntdApp>
  );
}

export function App() {
  return (
    <ConfigProvider theme={globalTheme}>
      <QueryClientProvider client={queryClient}>
        <InnerApp />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  );
}
